import './index.css';

const Footer = () => {
    return (
        <div className='footer'>
            <div className='container'>
                <div className='footer-content'>
                    @2024 Solana Copy Trading Bot
                </div>
            </div>
        </div>
    )
}

export default Footer;